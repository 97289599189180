.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.has-border {
    border-style: solid;
}

.canvas-container {
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
}