.participants {
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.panelHeader {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  font-family: "Mulish", sans-serif;
}

.inviteFriends {
  margin-top: 20px;
  display: flex;
  font-size: 16px;
  font-family: "Mulish", sans-serif;
  align-items: center;
  column-gap: 10px;
}

.call {
  margin-top: 20px;
  color: #A0A0A0;
  font-weight: bold;
  font-family: "Mulish", sans-serif;
}

.divider {
  margin-top: 20px;
  width: 100%;
  background: #3f3f3f;
  height: 2px;
}

.successText {
  color: #135d13;
  opacity: 1;
  transition-duration: .4s;
}

.hideSuccessText {
  opacity: 0;
  transition-duration: .4s;
}

.participants {

}
