.modal {
    background: rgba(0, 0, 0, 0.8);
    transition-duration: .3s;
}

.wrapper {
    max-width: 400px;
    padding: 20px;
    border-radius: 15px;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}