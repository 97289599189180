.root {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dbdbdb;
  padding: 10px;
}

.internalBLock {
  width: 450px;
}