.avatarInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  width: 42px;
  height: 42px;
  background: rgb(77, 227, 227);
  border: 2px solid rgb(230, 233, 242);
  border-radius: 50px;
  cursor: default;
}

.avatarInfo:hover {
  border: 2px solid rgb(77, 227, 227);
}

.participantName {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participantsInfo {
  display: flex;
  column-gap: 15px;
  align-items: center;
}