.tab {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #867400;
  cursor: pointer;
  background: #cbc6c6;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  transition-duration: .4s;
  white-space: nowrap;
}

.selectedTab {
  cursor: default;
  background: #beb7b7;
}

.text:hover {
  transition-duration: .4s;
  background: #a4a0a0;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuDirection_horizontal {
  flex-direction: column;
}

.menuDirection_vertical {
  flex-direction: row;
}