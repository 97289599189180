.input {
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  height: 54px;
}

.error {
  color: #ff0000;
  font-size: 12px;
  font-family: "Mulish", sans-serif;
}