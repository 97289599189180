.controlPanel {
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
}

.playButton {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.inputBlocks {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.input {
    width: 100%;
}

.actionsBLock {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.statistics {
    padding: 5px;
    border-radius: 15px;
    border: 1px solid #d3e1ec;
    text-align: center;
    width: 210px;
    cursor: grab;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    row-gap: 10px;
    transition-duration: .4s;
    flex-direction: column;
}

.statistics:hover {
    transition-duration: .4s;
    border:1px solid #0f1ece;
}

.statisticsBLock {
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    font-family: "Mulish", sans-serif;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    color: #340130;
}
